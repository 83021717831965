// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BZeXp1BpQ", "qmiSOUHYW", "HkoEMgXrv", "mL6xhqz_X", "UpC3gVDrT", "VRgefkcfT", "oCinIAMVE", "ry1bIPoA4", "yA1WVgpQP", "UiNsB6mJX", "sM0eYAcB3", "n8c_sGmH1", "ayrBhAvet", "X86_Yhze3"];

const serializationHash = "framer-d01PX"

const variantClassNames = {ayrBhAvet: "framer-v-1ce39qn", BZeXp1BpQ: "framer-v-12sispr", HkoEMgXrv: "framer-v-1ddtm47", mL6xhqz_X: "framer-v-otlp4w", n8c_sGmH1: "framer-v-neutl2", oCinIAMVE: "framer-v-tdfla0", qmiSOUHYW: "framer-v-1ggnrdl", ry1bIPoA4: "framer-v-10oe85l", sM0eYAcB3: "framer-v-1je9hhm", UiNsB6mJX: "framer-v-1eecv3q", UpC3gVDrT: "framer-v-hf5asl", VRgefkcfT: "framer-v-1nj3l8z", X86_Yhze3: "framer-v-wvepfo", yA1WVgpQP: "framer-v-jdvvu9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"$spacing-10": "BZeXp1BpQ", "$spacing-100": "HkoEMgXrv", "$spacing-15": "ayrBhAvet", "$spacing-20": "n8c_sGmH1", "$spacing-200": "qmiSOUHYW", "$spacing-25": "sM0eYAcB3", "$spacing-30": "UiNsB6mJX", "$spacing-40": "yA1WVgpQP", "$spacing-5": "X86_Yhze3", "$spacing-50": "ry1bIPoA4", "$spacing-60": "oCinIAMVE", "$spacing-70": "VRgefkcfT", "$spacing-80": "UpC3gVDrT", "$spacing-90": "mL6xhqz_X"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BZeXp1BpQ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BZeXp1BpQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-12sispr", className, classNames)} data-framer-name={"$spacing-10"} layoutDependency={layoutDependency} layoutId={"BZeXp1BpQ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ayrBhAvet: {"data-framer-name": "$spacing-15"}, HkoEMgXrv: {"data-framer-name": "$spacing-100"}, mL6xhqz_X: {"data-framer-name": "$spacing-90"}, n8c_sGmH1: {"data-framer-name": "$spacing-20"}, oCinIAMVE: {"data-framer-name": "$spacing-60"}, qmiSOUHYW: {"data-framer-name": "$spacing-200"}, ry1bIPoA4: {"data-framer-name": "$spacing-50"}, sM0eYAcB3: {"data-framer-name": "$spacing-25"}, UiNsB6mJX: {"data-framer-name": "$spacing-30"}, UpC3gVDrT: {"data-framer-name": "$spacing-80"}, VRgefkcfT: {"data-framer-name": "$spacing-70"}, X86_Yhze3: {"data-framer-name": "$spacing-5"}, yA1WVgpQP: {"data-framer-name": "$spacing-40"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d01PX.framer-18fk7sr, .framer-d01PX .framer-18fk7sr { display: block; }", ".framer-d01PX.framer-12sispr { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 10px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-d01PX.framer-12sispr { gap: 0px; } .framer-d01PX.framer-12sispr > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-d01PX.framer-12sispr > :first-child { margin-top: 0px; } .framer-d01PX.framer-12sispr > :last-child { margin-bottom: 0px; } }", ".framer-d01PX.framer-v-1ggnrdl.framer-12sispr { height: 200px; width: 200px; }", ".framer-d01PX.framer-v-1ddtm47.framer-12sispr { height: 100px; width: 100px; }", ".framer-d01PX.framer-v-otlp4w.framer-12sispr { height: 90px; width: 90px; }", ".framer-d01PX.framer-v-hf5asl.framer-12sispr { height: 80px; width: 80px; }", ".framer-d01PX.framer-v-1nj3l8z.framer-12sispr { height: 70px; width: 70px; }", ".framer-d01PX.framer-v-tdfla0.framer-12sispr { height: 60px; width: 60px; }", ".framer-d01PX.framer-v-10oe85l.framer-12sispr { height: 50px; width: 50px; }", ".framer-d01PX.framer-v-jdvvu9.framer-12sispr { height: 40px; width: 40px; }", ".framer-d01PX.framer-v-1eecv3q.framer-12sispr { height: 30px; width: 30px; }", ".framer-d01PX.framer-v-1je9hhm.framer-12sispr { height: 25px; width: 25px; }", ".framer-d01PX.framer-v-neutl2.framer-12sispr { height: 20px; width: 20px; }", ".framer-d01PX.framer-v-1ce39qn.framer-12sispr { height: 15px; width: 15px; }", ".framer-d01PX.framer-v-wvepfo.framer-12sispr { height: 5px; width: 5px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 10
 * @framerIntrinsicWidth 10
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"qmiSOUHYW":{"layout":["fixed","fixed"]},"HkoEMgXrv":{"layout":["fixed","fixed"]},"mL6xhqz_X":{"layout":["fixed","fixed"]},"UpC3gVDrT":{"layout":["fixed","fixed"]},"VRgefkcfT":{"layout":["fixed","fixed"]},"oCinIAMVE":{"layout":["fixed","fixed"]},"ry1bIPoA4":{"layout":["fixed","fixed"]},"yA1WVgpQP":{"layout":["fixed","fixed"]},"UiNsB6mJX":{"layout":["fixed","fixed"]},"sM0eYAcB3":{"layout":["fixed","fixed"]},"n8c_sGmH1":{"layout":["fixed","fixed"]},"ayrBhAvet":{"layout":["fixed","fixed"]},"X86_Yhze3":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerbm_U9WYgz: React.ComponentType<Props> = withCSS(Component, css, "framer-d01PX") as typeof Component;
export default Framerbm_U9WYgz;

Framerbm_U9WYgz.displayName = "Spacing 2";

Framerbm_U9WYgz.defaultProps = {height: 10, width: 10};

addPropertyControls(Framerbm_U9WYgz, {variant: {options: ["BZeXp1BpQ", "qmiSOUHYW", "HkoEMgXrv", "mL6xhqz_X", "UpC3gVDrT", "VRgefkcfT", "oCinIAMVE", "ry1bIPoA4", "yA1WVgpQP", "UiNsB6mJX", "sM0eYAcB3", "n8c_sGmH1", "ayrBhAvet", "X86_Yhze3"], optionTitles: ["$spacing-10", "$spacing-200", "$spacing-100", "$spacing-90", "$spacing-80", "$spacing-70", "$spacing-60", "$spacing-50", "$spacing-40", "$spacing-30", "$spacing-25", "$spacing-20", "$spacing-15", "$spacing-5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerbm_U9WYgz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})